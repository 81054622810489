import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/style.css";
import "../Home/Home.css";
import "../SidePages/SidePages.css";

const TermsOfCondition = () => {

  return (
  <div className="main">

    <div class="outer-content">
      <div className="container">
        <div className="terms-conditin-content">
          <h2>Welcome to Bridge The Gap (BTG) Technologies!</h2>
          <p>These terms and conditions outline the rules and regulations for using <b>Bridge The Gap (BTG) Technologies</b> Website, located at <a href="https://btgtechnologies.com.com/">https://btgtechnologies.com/</a> .</p>
          <p>By accessing this Website, we assume you accept these terms and conditions. Only continue to use Bridge The Gap (BTG) Technologies if you agree to take all of the terms and conditions stated on this page.</p>
          <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice, and all Agreements: "Client," "You," and "You're" refers to you, the person logged on to this Website and compliant with the Company's terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us" refers to our Company. "Party," "Parties," or "Us" refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client most appropriately for the express purpose of meeting the Client's needs in respect of the provision of the Company's stated services under and subject to, prevailing law of us. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they are taken as interchangeable and, therefore, as referring to the same.</p>
          <h3>Cookies</h3>
          <p>We employ the use of cookies. By accessing <b>Bridge The Gap (BTG) Technologies</b>, you agree to use cookies in Agreement with <b>Bridge The Gap (BTG) Technologies</b> Privacy Policy. Most interactive websites use cookies to let us retrieve the user's details for each visit. Our Website uses cookies to enable the functionality of certain areas to make it easier for people visiting our Website. Some of our affiliate/advertising partners may also use cookies.</p>
          <h3>License</h3>
          <p>Unless otherwise stated, <b>Bridge The Gap (BTG) Technologies</b> and its licensors own the intellectual property rights for all material on <b>Bridge The Gap (BTG) Technologies</b>. All intellectual property rights are reserved. You may access this from <b>Bridge The Gap (BTG) Technologies</b> for your personal use, subject to restrictions set in these terms and conditions. </p>
          <p>You must not:</p>
          <ul>
            <li>Republish material from <b>Bridge The Gap (BTG) Technologies</b></li>
            <li>Sell, rent, or sub-license material from <b>Bridge The Gap (BTG) Technologies</b></li>
            <li>Reproduce, duplicate, or copy material from <b>Bridge The Gap (BTG) Technologies</b></li>
            <li>Redistribute content from <b>Bridge The Gap (BTG) Technologies</b></li>
          </ul>
          <p>This Agreement shall begin on the date hereof.</p>
          <p>Parts of this Website offer an opportunity for users to post and exchange opinions and information in certain areas of the Website. <b>Bridge The Gap (BTG) Technologies</b> does not filter, edit, publish, or review Comments before their presence on the Website. Comments do not reflect the views and opinions of <b>Bridge The Gap (BTG) Technologies</b>, its agents, and affiliates. Statements reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, <b>Bridge The Gap (BTG) Technologies</b> shall not be liable for the Comments or any liability, damages, or expenses caused and suffered due to any use of and posting of and appearance of the Comments on this Website. </p>
          <p><b>Bridge The Gap (BTG) Technologies</b> reserves the right to monitor all Comments and remove any Comments that can be considered inappropriate, offensive, or causes a breach of these Terms and Conditions.</p>
          <p>You warrant and represent that:</p>
          <ul>
            <li>You are entitled to post the Comments on our Website and have all necessary licenses and consents to do so;</li>
            <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
            <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy</li>
            <li>The Comments will not be used to solicit or promote business, custom, or present commercial or unlawful activities.</li>
          </ul>
          <p>You hereby grant <b>Bridge The Gap (BTG) Technologies</b> a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce and edit any of your Comments in any forms, formats, or media. </p>
          <h3>Hyperlinking To Our Content</h3>
          <p>The following organizations may link to our Website without prior written approval:</p>
          <p>The following organizations may link to our Website without prior written approval:</p>
          <ul>
            <li>Search engines;</li>
            <li>News organizations;</li>
            <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
            <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups, which may not hyperlink to our Website.</li>
          </ul>
          <p>These organizations may link to our home page, publications, or other Website information so long as the link:</p>
          <ol>
            <li>It is not in any way deceptive.</li>
            <li>Does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and services.</li>
            <li>Fits within the context of the linking party's site.</li>
          </ol>
          <p>We may consider and approve other link requests from the following types of organizations:</p>
          <ul>
            <li>commonly-known consumer and business information sources;</li>
            <li>dot.com community sites;</li>
            <li>online directory distributors;</li>
            <li>internet portals;</li>
          </ul>
          <p>We will approve link requests from these organizations if we decide that:</p>
          <ol>
            <li>The link would not make us look unfavorably to ourselves or our accredited businesses.</li>
            <li>The organization does not have any negative records with us.</li>
            <li>The benefit to us from the visibility of the hyperlink compensates for the absence of <b>Bridge The Gap (BTG) Technologies</b>. </li>
            <li>The link is in the context of general resource information.</li>
          </ol>
          <p>These organizations may link to our home page so long as the link:</p>
          <ol>
            <li>It is not in any way deceptive.</li>
            <li>Does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services.</li>
            <li>Fits within the context of the linking party's site.</li>
          </ol>
          <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our Website, you must inform us by sending an e-mail to Bridge The Gap (BTG) Technologies. Please include your name, your organization name, and contact information, as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site you would like to link. Wait 2-3 weeks for a response.</p>
          <p>Approved organizations may hyperlink to our Website as follows:</p>
          <ol>
            <li>By use of our corporate name; or</li>
            <li>By use of the uniform resource locator being linked to; or</li>
            <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
          </ol>
          <p>No <b>Bridge The Gap (BTG) Technologies</b> logo or other artwork will be allowed for linking absent a trademark license agreement. </p>
          <h3>iFrames</h3>
          <p>Without prior approval and written permission, you may not create frames around our Webpages that alter our Website's visual presentation or appearance.</p>
          <h3>Content Liability</h3>
          <p>We shall not be held responsible for any content on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal or which infringes, otherwise violates, or advocates the infringement or other violation of any third-party rights.</p>
          <h3>Reservation of Rights</h3>
          <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve of immediately removing all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
          <h3>Removal Of Links From Our Website</h3>
          <p>If you find any link on our Website offensive for any reason, you are free to contact and inform us anytime. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly. We do not ensure that the information on this Website is correct; we do not warrant its completeness or accuracy; nor do we promise to ensure that the Website remains available or that the material on the Website is kept up to date. </p>
          <h3>Refund Policy</h3>
          <p>Our refund policy has defined the situations under which <b>Bridge The Gap (BTG) Technologies</b> will give a refund, the procedure for claiming a refund, and the responsibility of <b>Bridge The Gap (BTG) Technologies</b> in the circumstances resulting in such a claim. By registering our services, you are shown to accept and agree to all the terms and conditions outlined in the refund policy. All the information we gather is used to improve our website's content, notify the clients about our updates, or look into the issues with their request proposals. If you want to avoid getting emails from us in the future, please inform us by writing or calling us via phone number.</p>
          <h3>Cancellation Policy</h3>
          <p>Anytime clients want to discontinue a project; they should email us their cancellation request and any necessary supporting documentation. Our team will provide an invoice for the work accomplished at that precise moment, based on the resources utilized for that assignment, if a project is canceled due to any concern before it is finished. <b>Bridge The Gap (BTG) Technologies</b> will regard a client-side delay of more than 15 days to be a cancellation of the project, and it will begin again once we get a written request to do so. Depending on the resources utilized for the activity, we will send you an invoice for the number of tasks that were finished at that time.</p>
          <h3>Refund Policy</h3>
          <p>By signing up for our services, a client agrees to accept our return policy, followed by <b>Bridge The Gap (BTG) Technologies</b>. This refund policy does not apply to organizations, individuals, or websites operated by third parties that we do not own, control, or employ. Prioritizing all feasible efforts to find an agreeable solution should come before submitting a complaint for a refund. Refunds will only be considered a last resort if our clients and we cannot agree. Refund requests will only be considered if we cannot come to an amicable agreement. Every job is handled professionally and to the best of our ability to produce the outcomes promised beforehand. Nonetheless, under challenging circumstances, if we are unable to satisfy clients' requests, we will consider refunds in the following manner:</p>
          <ol>
            <li>If the project is not kicked off, 75% of the kick-start money will be refunded.</li>
            <li>The amount of the return will depend on the quantity of work completed if the project is discontinued at any point during execution, including after design creation, build development, or any other phases.</li>
            <li>Based on the resources utilized up until that point in the project, a partial reimbursement will be made.</li>
            <li>There won't be any refunds given if the project is finished and placed on the server.</li>
            <li>Within seven working days of the project's official cancellation date, refunds will be processed.</li>
          </ol>
          <h3>Restrictions on Liability</h3>
          <p>Any financial loss resulting from our generated projects, whether direct, indirect, or consequential, will not subject <b>Bridge The Gap (BTG) Technologies</b> to liability. We reserve the right to end any project at any moment and without incurring any liability. Indirect usage of any of our services may result in problems, damages, or downtime for which <b>Bridge The Gap (BTG) Technologies</b> is not liable. Services offered by third parties are considered separate from those provided by GFS. We can modify the refund/cancellation policy without prior notification. Please frequently examine the policy. Your continued use of our website following such modifications signifies that you agree.</p>
          <h3>Disclaimer</h3>
          <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our Website and the use of this Website. Nothing in this disclaimer will:</p>
          <ul>
            <li>limit or exclude our or your liability for death or personal injury;</li>
            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
          </ul>
          <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty. As long as the Website and the information and services on the Website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
        </div>
      </div>
    </div>

  </div>
  
  )
}

export default TermsOfCondition