import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";

const Testimonials = () => {

  const [show, setShow] = React.useState();

  // testimonialSlider
  var testimonialSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          vertical: false,
          verticalSwiping: false
        }
      }
    ]
  };

  return (
    <div className="">

      <div className="testimonials">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              {/* <Slider {...testimonialSlider}>
                <div>
                  <div className="box">
                    <img src={require('../../assets/images/home/testimonials/1.png')} alt="" />
                    <div className="text-area">
                      <h3>CASENDRALUE</h3>
                      <h4>Ceo Company</h4>
                      <p>"I can't thank the team at Bridge The Gap (BTG) Technologies enough for their exceptional work on developing our mobile application. They truly understood our vision and translated it into a visually stunning, user-friendly app. Our app has received rave user reviews, and we owe it all to Bridge The Gap (BTG) Technologies!"</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <img src={require('../../assets/images/home/testimonials/2.png')} alt="" />
                    <div className="text-area">
                      <h3>Micheal Clark</h3>
                      <h4>Ceo Company</h4>
                      <p>"Working with Bridge The Gap (BTG) Technologies was an absolute pleasure! The team went above and beyond to incorporate our brand identity seamlessly into the design with a responsive and optimized website. Since the launch, we've seen a significant increase in traffic and engagement on our website. Kudos to Bridge The Gap (BTG) Technologies for their expertise and professionalism!"</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <img src={require('../../assets/images/home/testimonials/3.png')} alt="" />
                    <div className="text-area">
                      <h3>Josephin Antwi</h3>
                      <h4>Ceo Company</h4>
                      <p>"Bridge The Gap (BTG) Technologies has been a game-changer for our business. Their strategic approach and innovative ideas have helped us achieve remarkable results on social media. They've not only increased our brand visibility but also engaged our target audience effectively. Thanks to their efforts, our social media presence has grown exponentially, and we've seen a boost in conversions. Highly recommended!"</p>
                    </div>
                  </div>
                </div>
              </Slider> */}
            </div>
            <div className="col-md-5 offset-md-1">
              <div className="right-side">
                <h2>Our <span>Happy Customers</span> Have To Share Something!</h2>
                <p>We treat our customers like royalty and go to extra lengths to make them satisfied with our services. Believe it or not, the sun never sets at Bridge The Gap (BTG) Technologies; our dedicated team misses no chance to deliver the commitment. Be it a website, a marketing campaign or a mobile application, the solution always exceeds our client’s expectations.</p>
                <p>We have a list of happy clients who flooded our inbox with their remarks. We thought of sharing it with the world because sharing is always CARING. Jokes apart, if you haven't decided to work with us, you are yet prepared for record-breaking SUCCESS!</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  )

}



export default Testimonials