import React from 'react'
import "../Thankyou/Thankyou.css";


const Thankyou = () => {
  return (
    <main>
      <div className='wrapper'>
      <img src={require('../../assets/images/Thankyou.png')} alt='thankyou' />
      <div>
        <h1 className='thanks-text'><p className='para m-0'> Thank You </p> For Contacting Us</h1>
        <h4>We have received your email. Our team will get in touch with you shortly.</h4>
        <p></p>
        </div>
      </div>
    </main>
  )
}

export default Thankyou
