import React, { useState, useRef, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";

import f1 from '../../assets/images/home/feature/f1.png'
import f2 from '../../assets/images/home/feature/f2.png'
import f3 from '../../assets/images/home/feature/f3.png'
import f4 from '../../assets/images/home/feature/f4.png'
import f5 from '../../assets/images/home/feature/f5.png'
import f6 from '../../assets/images/home/feature/f6.png'

import logo1 from '../../assets/images/home/feature/logo1.png'
import logo2 from '../../assets/images/home/feature/logo2.png'
import logo3 from '../../assets/images/home/feature/logo3.png'
import logo4 from '../../assets/images/home/feature/logo4.png'
import logo5 from '../../assets/images/home/feature/logo5.png'
import logo6 from '../../assets/images/home/feature/logo6.png'

import brand1 from '../../assets/images/home/feature/brand1.png'
import brand2 from '../../assets/images/home/feature/brand2.png'
import brand3 from '../../assets/images/home/feature/brand3.png'
import brand4 from '../../assets/images/home/feature/brand4.png'
import brand5 from '../../assets/images/home/feature/brand5.png'
import brand6 from '../../assets/images/home/feature/brand6.png'

import mobapp1 from '../../assets/images/home/feature/mobapp1.png'
import mobapp2 from '../../assets/images/home/feature/mobapp2.png'
import mobapp3 from '../../assets/images/home/feature/mobapp3.png'
import mobapp4 from '../../assets/images/home/feature/mobapp4.png'
import mobapp5 from '../../assets/images/home/feature/mobapp5.png'
import mobapp6 from '../../assets/images/home/feature/mobapp6.png'

import social1 from '../../assets/images/home/feature/social1.png'
import social2 from '../../assets/images/home/feature/social2.png'
import social3 from '../../assets/images/home/feature/social3.png'
import social4 from '../../assets/images/home/feature/social4.png'
import social5 from '../../assets/images/home/feature/social5.png'
import social6 from '../../assets/images/home/feature/social6.png'


const allImages = [
  {
    id: 1,
    img_src: f1,
    type: "website_design"
  },
  {
    id: 2,
    img_src: f2,
    type: "website_design"
  },
  {
    id: 3,
    img_src: f3,
    type: "website_design"
  },
  {
    id: 4,
    img_src: f4,
    type: "website_design"
  },
  {
    id: 5,
    img_src: f5,
    type: "website_design"
  },
  {
    id: 6,
    img_src: f6,
    type: "website_design"
  },

  {
    id: 7,
    img_src: logo1,
    type: "logo_design"
  },
  {
    id: 8,
    img_src: logo2,
    type: "logo_design"
  },
  {
    id: 9,
    img_src: logo3,
    type: "logo_design"
  },
  {
    id: 10,
    img_src: logo4,
    type: "logo_design"
  },
  {
    id: 11,
    img_src: logo5,
    type: "logo_design"
  },
  {
    id: 12,
    img_src: logo6,
    type: "logo_design"
  },

  {
    id: 13,
    img_src: brand1,
    type: "branding_design"
  },
  {
    id: 14,
    img_src: brand2,
    type: "branding_design"
  },
  {
    id: 15,
    img_src: brand3,
    type: "branding_design"
  },
  {
    id: 16,
    img_src: brand4,
    type: "branding_design"
  },
  {
    id: 17,
    img_src: brand5,
    type: "branding_design"
  },
  {
    id: 18,
    img_src: brand6,
    type: "branding_design"
  },

  {
    id: 19,
    img_src: mobapp1,
    type: "mobile_application"
  },
  {
    id: 20,
    img_src: mobapp2,
    type: "mobile_application"
  },
  {
    id: 21,
    img_src: mobapp3,
    type: "mobile_application"
  },
  {
    id: 22,
    img_src: mobapp4,
    type: "mobile_application"
  },
  {
    id: 23,
    img_src: mobapp5,
    type: "mobile_application"
  },
  {
    id: 24,
    img_src: mobapp6,
    type: "mobile_application"
  },

  {
    id: 25,
    img_src: social1,
    type: "social_media_design"
  },
  {
    id: 26,
    img_src: social2,
    type: "social_media_design"
  },
  {
    id: 27,
    img_src: social3,
    type: "social_media_design"
  },
  {
    id: 28,
    img_src: social4,
    type: "social_media_design"
  },
  {
    id: 29,
    img_src: social5,
    type: "social_media_design"
  },
  {
    id: 30,
    img_src: social6,
    type: "social_media_design"
  },


]

const Feature = () => {
  const [search, setSearch] = useState("logo_design")

  const [show, setShow] = React.useState();

  return (
    <div className="">

      <div className="feature-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h2>The Areas Of <span>Our Expertise</span></h2>
              <p>We've tackled more projects at Bridge The Gap (BTG) Technologies than you can shake a stick at. From designing sleek logos that pack a punch to developing mobile applications that knock it out of the park, we've done it all. So, trust us to take your project to the next level because we're the bee's knees at what we do.</p>
              <ul>
                <li className={search === "logo_design" ? 'active' : ''}><button onClick={() => setSearch("logo_design")}>Logo Design</button></li>
                <li className={search === "website_design" ? 'active' : ''}><button onClick={() => setSearch("website_design")}>Website Design</button></li>
                <li className={search === "branding_design" ? 'active' : ''}><button onClick={() => setSearch("branding_design")}>Branding Design</button></li>
                <li className={search === "social_media_design" ? 'active' : ''}><button onClick={() => setSearch("social_media_design")}>Social Media Design</button></li>
                <li className={search === "mobile_application" ? 'active' : ''}><button onClick={() => setSearch("mobile_application")}>Mobile Application</button></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="img-area">
          {allImages?.filter((item => item.type === search))?.map((item) => (
            <img src={item.img_src} alt="" />

          ))}

        </div>
      </div>

    </div>


  )

}



export default Feature