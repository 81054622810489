import React, { useState} from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";
import { Spinner } from "react-bootstrap";

const Footer = () => {

  const [showMessage, setshowMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState("");
  const [search, setSearch] = useState(null)
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [description, setdescription] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [selectedValue, setSelectedValue] = useState('');
  const navigate = useNavigate();

  // Formik Form validations
  const HandleFormValidation = useFormik({
    // validationSchema: Yup.object().shape({
    //   Name: Yup.string().required("This field is required"),
    //   EmailAddress: Yup.string()
    //     .email("Invalid email address")
    //     .required("Email is required"),
    //     Description: Yup.string().required("This field is required"),
    //     CategoryId: Yup.string().required("This field is required"),
    //     Phone: Yup.string().required("Mobile number is required"),
    // }),
    initialValues: {
      Name: "",
      EmailAddress: "",
      Phone: "",
      Description : "",
      CategoryId : "",
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("done")
      const body = {
        CategoryId : `${selectedValue}`,
        Name: `${values.Name} `,
        EmailAddress: `${values.EmailAddress}`,
        Phone :  `${values.Phone}`,
        Description : "BTG-BLockchain</br>" + values.Description,
        // service: "Bridge The Gap (BTG) Technologies | Branding",
      };
      setLoading(true)
      axios
      .post("https://api.delta19seafood.com/api/lead/CreateLeadFromWbsite", body)
        .then((response) => {
          navigate('/thankyou');
          setshowMessage(response.data);
          resetForm();
          setSubmitting(false);
          setTimeout(() => {
            setshowMessage("");
          }, 3000);
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          seterror(error);
          setSubmitting(false);
          setTimeout(() => {
            seterror("");
          }, 3000);
        });
      console.log(values);
    },
  });

  const isFormikValid = (name) =>
    !!(HandleFormValidation.touched[name] && HandleFormValidation.errors[name]);
  const getFormMessage = (name) => {
    return (
      isFormikValid(name) && (
        <p
          className="small m-0"
          style={{ color: "red", fontSize: "12px", paddingLeft: '10px' }}
        >
          {HandleFormValidation.errors[name]}
        </p>
      )
    );
  };
    // State variable to store the selected value

    const handleSelectChange = (event) => {
      const value = event.target.value; // Get the selected value from the event
      setSelectedValue(value); // Update the state with the selected value
    };

  return (
    <div className="">
      <div className="contact-sec" id="contactUs">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
                <h2><span>Bridge The Gap (BTG) Technologies</span> Is The Only Name That Guarantees The <span>Smoothest Launch</span> Of Your Business!</h2>
                <p>Break the ice, and smash your marketing goals! If you have any business ideas and want to transform them into the most approached brand of all times, let us burn the midnight oil for you. All it takes is a single call, and Voilà, you are already halfway to your success.</p>
                <div className="btns-area">
                  <a href="javascript:void(Tawk_API.toggle())" className="btn">LIVE CHAT</a>
                  <a href="tel:+17273080419" className="btn danger">CALL US NOW</a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <form onSubmit={HandleFormValidation.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Full Name</label>
                      <input onChange={HandleFormValidation.handleChange} name="Name"
                        value={HandleFormValidation.values.Name} className="form-control" type="text" required  />
                    </div>
                    {getFormMessage("Name")}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Phone Number</label>
                      <input onChange={HandleFormValidation.handleChange} name="Phone"
                        value={HandleFormValidation.values.Phone} className="form-control" type="number" required />
                    </div>
                    {getFormMessage("Phone")}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Email Address</label>
                      <input onChange={HandleFormValidation.handleChange} name="EmailAddress"
                        value={HandleFormValidation.values.EmailAddress} className="form-control" type="text" required />
                    </div>
                    {getFormMessage("EmailAddress")}
                  </div>
                  <div className="form-group col-md-6">
                  <label>Choose Catagory</label>
                    <select className="form-control" value={selectedValue} onChange={handleSelectChange} >
                    <option selected>Select</option>
                      <option value="7148B03E-93C9-416A-9EA0-658E10C3A711">Logo Design</option>
                      <option value="A4D68300-4683-436A-96E5-84143B92103C">Website Design and Development</option>
                      <option value="4CB3A3FF-2593-4513-86F9-D4E9DEB058F8">Branding Design</option>
                      <option value="879652F1-122E-4C1E-8A55-CC3B5848F6E9">Digital Marketing</option>
                      <option value="4CC1100C-89CF-4C8A-A3A3-9BF1F30C789F">Mobile App Development</option>
                      <option value="487778AD-7198-4A29-9094-E87B55A1F273">Other</option>
                    </select>
                </div>
                {getFormMessage("category ")}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Project Brief</label>
                      <textarea onChange={HandleFormValidation.handleChange} name="Description"
                      value={HandleFormValidation.values.Description} id="" className="form-control" rows={4} required ></textarea>
                  </div>
                    {getFormMessage("Description ")}
                  </div>
                  <div className="col-md-12">
                  {error?.Description  === "Request failed with status code 400"
                    ? <p style={{fontSize: '13px',textAlign:'center',color:"#DF0003",fontWeight:'600',fontSize:"20px"}}>There was an error submitting the form.</p> : ""}
                    
                    <p style={{fontSize: '13px',color:'green',fontWeight:600,textAlign:'left', margin:0}}>{showMessage.Description }</p>
                    <div className="send-btn">
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="submit-btn">
                    {loading ? <><Spinner animation="border" variant="primary" /></> : <>Submit Now</>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <footer>
        <div className="footer-links">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <h3>About</h3>
                <h2 className="footer-logo">Bridge The Gap (BTG) Technologies</h2>
                <p className="extra-p">Bridge The Gap (BTG) Technologies is a US-based Tech Company aiming to be the preferred IT Solutions Partner, thriving on creative solutions and products while utilizing technology and service excellence.</p>
                <a className="phone" href="tel:+17273080419">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span> +1 727 308 0419</span>
                </a>
                <br />
                <a className="phone" href="https://www.google.com/maps/place/5642+S+Park+Ave,+Morton+Grove,+IL+60053,+USA/@42.0374629,-87.7688055,17z/data=!3m1!4b1!4m6!3m5!1s0x880fcf4832f326a3:0x3e74c7f6fdb01039!8m2!3d42.0374629!4d-87.7688055!16s%2Fg%2F11c1cp0ygp?entry=ttu" target="_blank">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span> 7901 4TH Street North <br />St. Petersburg FL, 33702. USA</span>
                </a>
                <br />
                <img className="payment-img" src={require('../../assets/images/home/footer/payment-img.png')} alt="" />
              </div>
              <div className="col-lg-3 col-md-6">
                <h3>Company</h3>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="/about">ABOUT US</a></li>
                  <li><a href="/services">SERVICES</a></li>
                  {/* <li><a href="/testimonials">CLIENTS REVIEW</a></li> */}
                  <li><a href="/contact">CONTACT US</a></li>
                  <li><a href="/privacy-policy">PRIVACY POLICY</a></li>
                  <li><a href="/terms-condition">TERMS & CONDITION</a></li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6">
                <h3>Newsletter</h3>
                <h4>SUBSCRIBE FOR OUR NEWSLETTER</h4>
                <p>Keep up with the newest trends and tech solutions for your company's success by subscribing to our newsletter.</p>
                <form>
                  <div className="form-group">
                    <input className="form-control" type="text" placeholder="Email Address" />
                    <button type="button" className="submit-btn"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </div>
                </form>
                <div className="social">
                  <h4>Follow Us</h4>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/bridgethegapbtgtechnologies/" target="_blank">
                        <img className="payment-img" src={require('../../assets/images/home/footer/s1.png')} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/bridgethegapbtgtechnologies/" target="_blank">
                        <img className="payment-img" src={require('../../assets/images/home/footer/s2.png')} alt="" />
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://www.linkedin.com/" target="_blank">
                        <img className="payment-img" src={require('../../assets/images/home/footer/s3.png')} alt="" />
                      </a>
                    </li> */}
                    <li>
                      <a href="https://x.com/btgtechnologies/" target="_blank">
                        <img className="payment-img" src={require('../../assets/images/home/footer/s4.png')} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <p>Copyright &copy; 2023 Bridge The Gap (BTG) Technologies. All Rights Reserved</p>
          </div>
        </div>
      </footer>

    </div>


  )

}



export default Footer