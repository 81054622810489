import React, { useState, useRef, useEffect } from "react";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "../../assets/css/style.css";
import "../Home/Home.css";
import "../Contact/Contact.css";
import Banner from "../../components/banner/banner";
import Popup from '../../components/popup/popup';


const Contact = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [timedPopup, setTimedPopup] = useState(false)

  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimedPopup(true)
    }, 3000);
  }, [])

   // ServiceSlider
   var ServiceSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  // testimonialSlider
  var testimonialSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          vertical: false
        }
      }
    ]
  };

  return (
  <div className="main">
  <Popup trigger={timedPopup} setTrigger={setTimedPopup} />
      <a onClick={() => setTimedPopup(true)} className={scrollPosition >= 500 ? 'side-btn active' : 'side-btn'}>Contact Us</a>
      <Banner 
      heading={<h1>Get In <span>Touch</span></h1>} 
      description={<p>Strike up a conversation to let us build a success ladder for you, that too, at the earliest. All it takes is a single call, and here you have already started listening to the footstep of your ideal prospects at your door!</p>}
      button={<a href="#contactUs" className="btn">Explore More</a>}
      />

    <div className="achievements">
      <div className="container">
        <Marquee gradient={false} width="100%" speed={50} direction="left">
          <img src={require('../../assets/images/home/badges/b1.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b2.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b3.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b4.webp')} alt="" />
          <img src={require('../../assets/images/home/badges/b5.webp')} alt="" />
          <img src={require('../../assets/images/home/badges/b6.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b7.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b8.png')} alt="" />
        </Marquee>
      </div>
    </div>


  </div>

    
  )
}

export default Contact