import React, { useState, useRef, useEffect } from "react";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "../../assets/css/style.css";
import "../Home/Home.css";
import "./Services.css";
import Banner from "../../components/banner/banner";
import FAQ from "../../components/faq/faq";
import Feature from "../../components/feature/feature";
import Testimonials from "../../components/testimonials/testimonials";
import Technologies from "../../components/technologies/technologies";
import Pricings from "../../components/pricings/pricings";
import Popup from "../../components/popup/popup";

const Services = () => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const [timedPopup, setTimedPopup] = useState(false)
  
  
    useEffect(() => {
      function handleScroll() {
        setScrollPosition(window.scrollY);
      }
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    useEffect(() => {
      setTimeout(() => {
        setTimedPopup(true)
      }, 3000);
    }, [])

   // MainServiceSlider
   var MainServiceSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  // testimonialSlider
  var testimonialSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          vertical: false
        }
      }
    ]
  };

  return (
  <div className="main">
 <Popup trigger={timedPopup} setTrigger={setTimedPopup} />
      <a onClick={() => setTimedPopup(true)} className={scrollPosition >= 500 ? 'side-btn active' : 'side-btn'}>Contact Us</a>
      <Banner 
      heading={<h1>Explore Our <span>Services</span></h1>} 
      description={<p>Bridge The Gap (BTG) Technologies customizes solutions to meet your digital business needs. We are offering comprehensive services ranging from logo design to Mobile App Development.</p>}
      button={<a href="#services" className="btn">Explore More</a>}
       />

    <div className="achievements">
      <div className="container">
        <Marquee gradient={false} width="100%" speed={50} direction="left">
          <img src={require('../../assets/images/home/badges/b1.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b2.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b3.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b4.webp')} alt="" />
          <img src={require('../../assets/images/home/badges/b5.webp')} alt="" />
          <img src={require('../../assets/images/home/badges/b6.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b7.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b8.png')} alt="" />
        </Marquee>
      </div>
    </div>

    
    <div className="services" id="services">
        <Slider {...MainServiceSlider }>
            <div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-10 offset-md-1">
                            <div className="top-content">
                                <h2>Supercharge <span>Your<br />Brand</span> with a Custom Logo</h2>
                                <p>Stand out, ignite your brand, and attract more customers with a custom-designed logo for your business. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="text-area">
                                <h3><span>Welcome to our</span> Logo Design Service!</h3>
                                <p>We understand the significance of an interesting logo that represents your company. Our skilled designers are committed to creating a unique logo that captures your business' essence.</p>
                                <p>Experience the power of a visually impactful logo designed specifically for you. Trust our team to craft a logo that mirrors your brand's identity and leaves a lasting impression.</p>
                                <div className="btn-area">
                                    <a href="#contactUs" class="btn">Start your project</a>
                                    <a href="javascript:void(Tawk_API.toggle())" class="btn danger">Live chat</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 offset-lg-1">
                            <div className="img-area">
                                <img src={require('../../assets/images/services/2-1.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-10 offset-md-1">
                            <div className="top-content">
                                <h2>Bridge The Gap (BTG) Technologies - Where Human <span>Ingenuity Meets Authentic</span> Branding Design!</h2>
                                <p>Welcome to Bridge The Gap (BTG) Technologies, your one-stop shop for compelling and authentic branding design that can propel your business to new skies!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="text-area">
                                <h3>We are a <span>Destination for Authentic</span> Branding Design!</h3>
                                <p>Bridge The Gap (BTG) Technologies embrace the creativity and skill of human hands. Our dedicated designers craft distinct brand identities that embody your company's principles and resonate with your desired customer base.</p>
                                <p>Experience the magic of a tailor-made brand identity that captures the essence of your business. Trust our team to create an interesting brand identity that authentically represents your values and connects with your target audience.</p>
                                <div className="btn-area">
                                    <a href="#contactUs" class="btn">Start your project</a>
                                    <a href="javascript:void(Tawk_API.toggle())" class="btn danger">Live chat</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 offset-lg-1">
                            <div className="img-area">
                                <img src={require('../../assets/images/services/5-1.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-10 offset-md-1">
                            <div className="top-content">
                                <h2>Crafting Customized and <span>Impactful Social Media Designs</span> with Bridge The Gap (BTG) Technologies</h2>
                                <p>With Bridge The Gap (BTG) Technologies as your partner for social media design, you can expect customized and impactful designs that make a lasting impression in the digital world.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="text-area">
                                <h3><span>Bridge The Gap (BTG) Technologies</span> - Your Partner for Engaging and Impactful <span>Social Media Design!</span></h3>
                                <p>In the fast-paced digital landscape, social media plays a vital role in connecting with your audience and expanding your business. At Bridge The Gap (BTG) Technologies, we understand the significance of making an interesting first impression in this dynamic realm.</p>
                                <p>Unlock the potential of captivating and impactful social media design that leaves a lasting mark. Partner with us to enhance your social media presence and engage your target audience effectively.</p>
                                <div className="btn-area">
                                    <a href="#contactUs" class="btn">Start your project</a>
                                    <a href="javascript:void(Tawk_API.toggle())" class="btn danger">Live chat</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 offset-lg-1">
                            <div className="img-area">
                                <img src={require('../../assets/images/services/3-1.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-10 offset-md-1">
                            <div className="top-content">
                                <h2>Expert Mobile <span>App Design Services:</span> Bridge The Gap (BTG) Technologies at Your Service</h2>
                                <p>Bridge The Gap (BTG) Technologies' skilled design services can help you turn your mobile app vision into an actuality. Our talented team is committed to developing excellent mobile experiences that capture users and boost your brand.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="text-area">
                                <h3>We are an Expert <span>Partner for Exceptional</span> Mobile App Design!</h3>
                                <p>In the era of mobile dominance, a well-crafted and user-centric mobile app is paramount for business success. Bridge The Gap (BTG) Technologies recognizes the importance of creating captivating mobile app experiences that drive real-world impact.</p>
                                <p>Embark on a journey of innovative mobile app design that delights users and achieves tangible results. Join us to boost your mobile app presence and captivate your audience with a seamless and intuitive experience.</p>
                                <div className="btn-area">
                                    <a href="#contactUs" class="btn">Start your project</a>
                                    <a href="javascript:void(Tawk_API.toggle())" class="btn danger">Live chat</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 offset-lg-1">
                            <div className="img-area">
                                <img src={require('../../assets/images/services/4-1.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-10 offset-md-1">
                            <div className="top-content">
                                <h2>Bridge The Gap (BTG) Technologies: Crafting <span>Stunning Websites</span> for Your Business</h2>
                                <p>We are a professional web design and development firm specializing in developing visually appealing and functional websites. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="text-area">
                                <h3>Our Visually Appealing Web <span>Design & Development</span> Services</h3>
                                <p>We understand the importance of leaving a lasting impression with a captivating website. At Bridge The Gap (BTG) Technologies, our meticulous web development and visually appealing design services enable you to harness the full potential of your online presence.</p>
                                <p>Immerse your audience in a seamless fusion of stunning visuals and flawless functionality that brings your brand to life. Partner with us to create a visually striking website that captivates your audience and sets you apart in the digital landscape.</p>
                                <div className="btn-area">
                                    <a href="#contactUs" class="btn">Start your project</a>
                                    <a href="javascript:void(Tawk_API.toggle())" class="btn danger">Live chat</a>
                                </div>
                            </div>
                         </div>
                        <div className="col-md-6 offset-lg-1">
                            <div className="img-area">
                                <img src={require('../../assets/images/services/1-1.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Slider>
    </div>

    <Feature />

    <div className="stats">
      <div className="row gx-0">
        {/* <div className="col-md-3">
          <div className="box">
            <h3>$750M+</h3>
            <p>Customer Revenue Growth</p>
          </div>
        </div> */}
        <div className="col-md-4">
          <div className="box">
            <h3>300+</h3>
            <p>Currennt Customer</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box">
            <h3>900+</h3>
            <p>Our TALENTED TEAM</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box">
            <h3>20K</h3>
            <p>Clients Happy</p>
          </div>
        </div>
      </div>
    </div>

    <Pricings />

    <div className="process">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <h2>Our <span>Work</span> Process</h2>
              <h3>Sparking Ideas</h3>
              <p>We kick off our work process by brainstorming ideas. We mix and match concepts, shake things up like a cocktail, and let our creative juices flow like a wild river. From design inspirations to marketing strategies, we'll set your project ablaze with our fiery ideas.</p>
              <h3>Crafting Magic</h3>
              <p>Once we've captured the essence of your vision, we embark on a journey of craftsmanship. We meticulously sculpt designs, code websites with finesse, and create marketing campaigns that hit the bullseye.</p>
              <h3>Collaboration Extraordinaire</h3>
              <p>We foster a collaborative environment where your dreams take center stage. We listen to your desires, understand your needs, and twirl our expertise to deliver results. From start to finish, we will be your partners on this dance floor, creating a harmonious masterpiece together.</p>
              <h3>Sizzling Success</h3>
              <p>Our work process isn't complete until we've set the world ablaze with your project. We launch your designs, websites, and marketing campaigns like fireworks on a starry night. We aim to achieve sizzling success that leaves your competitors in the dust. So, let's set the world ablaze together!</p>
            </div>
            <div className="col-lg-7 offset-lg-1 col-md-7">
              <img className="side-img" src={require('../../assets/images/home/process/process-img.png')} alt="" />
            </div>
          </div>
        </div>
      </div>

    <Technologies />

    <Testimonials />

    <FAQ />
      
  </div>

    
  )
}

export default Services