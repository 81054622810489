import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

const Banner = ({ heading, description, button }) => {

  const [showMessage, setshowMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState("");
  const [search, setSearch] = useState(null)
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phonenumber, setphoneno] = useState("");
  const [message, setmessage] = useState("");
  const [selectedValue, setSelectedValue] = useState('');
  const navigate = useNavigate();

  const HandleFormValidation = useFormik({
    // validationSchema: Yup.object().shape({
    //   Name: Yup.string().required("This field is required"),
    //   EmailAddress: Yup.string()
    //     .email("Invalid email address")
    //     .required("Email is required"),
    //   Description: Yup.string().required("This field is required"),
    //   CategoryId: Yup.string().required("This field is required"),
    //   Phone: Yup.string().required("Mobile number is required"),
    // }),
    initialValues: {
      Name: "",
      EmailAddress: "",
      Phone: "",
      Description: "",
      CategoryId: "",
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("done")
      const body = {
        CategoryId: `${selectedValue}`,
        Name: `${values.Name} `,
        EmailAddress: `${values.EmailAddress}`,
        Phone: `${values.Phone}`,
        Description: "BTG-Branding</br>" + values.Description,
        // service: "Bridge The Gap (BTG) Technologies | Branding",
      };
      setLoading(true)
      axios
        .post("https://api.delta19seafood.com/api/lead/CreateLeadFromWbsite", body)
        .then((response) => {
          navigate('/Thankyou');
          setshowMessage(response.data);
          resetForm();
          setSubmitting(false);
          setTimeout(() => {
            setshowMessage("");
          }, 3000);
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          seterror(error);
          setSubmitting(false);
          setTimeout(() => {
            seterror("");
          }, 3000);
        });
    },
  });





  const isFormikValid = (name) =>
    !!(HandleFormValidation.touched[name] && HandleFormValidation.errors[name]);
  const getFormMessage = (name) => {
    return (
      isFormikValid(name) && (
        <p
          className="small m-0"
          style={{ color: "red", fontSize: "12px", paddingLeft: '10px' }}
        >
          {HandleFormValidation.errors[name]}
        </p>
      )
    );
  };
  // State variable to store the selected value

  const handleSelectChange = (event) => {
    const value = event.target.value; // Get the selected value from the event
    setSelectedValue(value); // Update the state with the selected value
  };
  return (
    <div className="">
      <div className="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text-area">
                {heading}
                {description}
                {button}
              </div>
            </div>
            <div className="col-xl-4 offset-xl-2 col-lg-5 offset-lg-1 col-md-6">
              <div className="form-area">
                <div className="box">
                  <div className="offer">
                    <p>limited time offer<br />avail <span>70%</span> discount now</p>
                  </div>
                  <form onSubmit={HandleFormValidation.handleSubmit}>
                    <div className="form-group">
                      <label>Full Name</label>
                      <input onChange={HandleFormValidation.handleChange} name="Name"
                        value={HandleFormValidation.values.Name} type="text" className="form-control" required />

                    </div>
                    {getFormMessage("Name")}
                    <div className="form-group">
                      <label>Email Address</label>
                      <input onChange={HandleFormValidation.handleChange} name="EmailAddress"
                        value={HandleFormValidation.values.EmailAddress} type="text" className="form-control" required />
                    </div>
                    {getFormMessage("EmailAddress")}
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input onChange={HandleFormValidation.handleChange} name="Phone"
                        value={HandleFormValidation.values.Phone} type="number" className="form-control" required />
                    </div>
                    {getFormMessage("Phone")}
                    <div className="form-group">
                      <label>Choose Catagory</label>
                      <select className="form-control" value={selectedValue} onChange={handleSelectChange} >
                        <option selected>Select</option>
                        <option value="7148B03E-93C9-416A-9EA0-658E10C3A711">Logo Design</option>
                        <option value="A4D68300-4683-436A-96E5-84143B92103C">Website Design and Development</option>
                        <option value="4CB3A3FF-2593-4513-86F9-D4E9DEB058F8">Branding Design</option>
                        <option value="879652F1-122E-4C1E-8A55-CC3B5848F6E9">Digital Marketing</option>
                        <option value="4CC1100C-89CF-4C8A-A3A3-9BF1F30C789F">Mobile App Development</option>
                        <option value="487778AD-7198-4A29-9094-E87B55A1F273">Other</option>
                      </select>
                    </div>
                    {getFormMessage("category ")}
                    <div className="form-group">
                      <label>Your Message</label>
                      <textarea onChange={HandleFormValidation.handleChange} name="Description"
                        value={HandleFormValidation.values.Description} id="" className="form-control" rows={4} required></textarea>
                    </div>
                    {getFormMessage("Description")}
                    {error?.Description === "Request failed with status code 400"
                      ? <p style={{ fontSize: '13px', textAlign: 'center', color: "#DF0003", fontWeight: '600', fontSize: "20px" }}>There was an error submitting the form.</p> : ""}

                    <p style={{ fontSize: '13px', color: 'green', fontWeight: 600, textAlign: 'center' }}>{showMessage.Description}</p>
                    <div className="send-btn">
                    </div>
                    <button type="submit" className="submit-btn">
                      {loading ? <><Spinner animation="border" variant="primary" /></> : <>Submit Now</>}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  )

}



export default Banner