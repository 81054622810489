import React, { useState, useRef, useEffect } from "react";
import Marquee from "react-fast-marquee";
import "../../assets/css/style.css";
import "../Home/Home.css";
import "../Testimonials/Testimonials.css";
import Banner from "../../components/banner/banner";
import FAQ from "../../components/faq/faq";

const Testimonials = () => {

  return (
  <div className="main">

      <Banner 
      heading={<h1>Client’s <br /><span>Reviews</span></h1>} 
      description={<p>Our clients have been our raving fans whose happy experiences are worth sharing with you. Have a look at their shares so that you can easily confide in our unmatchable services.</p>}
      button={<a href="#clientreviews" className="btn">Explore More</a>}
       />

    <div className="achievements">
      <div className="container">
        <Marquee gradient={false} width="100%" speed={50} direction="left">
          <img src={require('../../assets/images/home/badges/b1.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b2.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b3.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b4.webp')} alt="" />
          <img src={require('../../assets/images/home/badges/b5.webp')} alt="" />
          <img src={require('../../assets/images/home/badges/b6.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b7.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b8.png')} alt="" />
        </Marquee>
      </div>
    </div>

    <div className="client-reviews" id="clientreviews">
      <div className="container">
        <div className="top-content">
          <h2>What Our <span>Clients Speak</span> Out About Our Services</h2>
          <p>Discover our clients' glowing reviews and feedback on our services. Browse through our testimonials for a transparent and accountable look at the level of service we provide.</p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="box">
              <div className="img-area">
                <img src={require('../../assets/images/testimonials/img1.png')} alt="" />
              </div>
              <div className="box-haed">
                <div className="text">
                  <h3>CASENDRALUE</h3>
                  <p>Ceo Company</p>
                </div>
                <div className="stars">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <div className="box-body">
                <p>I can't thank the team at GFS Digital Agency enough for their exceptional work on developing our mobile application. They truly understood our vision and translated it into a visually stunning, user-friendly app. Our app has received rave user reviews, and we owe it all to GFS Digital Agency!</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box">
              <div className="img-area">
                <img src={require('../../assets/images/testimonials/img2.png')} alt="" />
              </div>
              <div className="box-haed">
                <div className="text">
                  <h3>Micheal Clark</h3>
                  <p>Ceo Company</p>
                </div>
                <div className="stars">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <div className="box-body">
                <p>Working with GFS Digital Agency was an absolute pleasure! The team went above and beyond to incorporate our brand identity seamlessly into the design with a responsive and optimized website. Since the launch, we've seen a significant increase in traffic and engagement on our website. Kudos to GFS Digital Agency for their expertise and professionalism!</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box">
              <div className="img-area">
                <img src={require('../../assets/images/testimonials/img3.png')} alt="" />
              </div>
              <div className="box-haed">
                <div className="text">
                  <h3>Josephin Antwi</h3>
                  <p>Ceo Company</p>
                </div>
                <div className="stars">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <div className="box-body">
                <p>GFS Digital Agency has been a game-changer for our business. Their strategic approach and innovative ideas have helped us achieve remarkable results on social media. They've not only increased our brand visibility but also engaged our target audience effectively. Thanks to their efforts, our social media presence has grown exponentially, and we've seen a boost in conversions. Highly recommended!</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box">
              <div className="img-area">
                <img src={require('../../assets/images/testimonials/img4.png')} alt="" />
              </div>
              <div className="box-haed">
                <div className="text">
                  <h3>Brent</h3>
                  <p>Ceo Company</p>
                </div>
                <div className="stars">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <div className="box-body">
                <p>Service was fast and great turn around. The logo turned out great. My team and I love it.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box">
              <div className="img-area">
                <img src={require('../../assets/images/testimonials/img5.png')} alt="" />
              </div>
              <div className="box-haed">
                <div className="text">
                  <h3>Che Stone</h3>
                  <p>Ceo Company</p>
                </div>
                <div className="stars">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <div className="box-body">
                <p>Loved everything about the service. Alex was very helpful and on top of everything. Loved my logo, will be back for other services.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box">
              <div className="img-area">
                <img src={require('../../assets/images/testimonials/img6.png')} alt="" />
              </div>
              <div className="box-haed">
                <div className="text">
                  <h3>Mary James</h3>
                  <p>Ceo Company</p>
                </div>
                <div className="stars">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <div className="box-body">
                <p>I chanced upon GFS Digital Agency and was amazed by their services that, in the future, I will be signing up with none but them. Their team didn’t keep me hanging and informed me with even a minute progress made. Their developed website brought me terrific traffic that my team is now juggling to manage all leads. Highly recommended!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FAQ />

  </div>

    
  )
}

export default Testimonials