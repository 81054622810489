import React, { useState, useRef, useEffect } from "react";
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";

const Technologies = () => {

  const [show, setShow] = React.useState();

  return (
    <div className="">
      
      <div className="technologies-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <h2>Be A Light Years Ahead Of Your Competitors, Because You Have US!</h2>
              <p>At Bridge The Gap (BTG) Technologies, we're not content with resting on our laurels. Our team always looks for the latest in frameworks, languages, digital strategies, marketing campaigns, and designs. We're not afraid to think outside the box and use cutting-edge technologies that others have yet to hear of. We'll pull out all the stops and go the extra mile to ensure your brand shines like a diamond in the rough. So, why settle for mediocrity when you can have excellence with us?</p>
            </div>
          </div>
          <ul>
            <li><img src={require('../../assets/images/home/tech/tech-1.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-2.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-3.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-4.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-5.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-6.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-7.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-8.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-9.png')} alt="" /></li>
            <li><img src={require('../../assets/images/home/tech/tech-10.png')} alt="" /></li>
          </ul>
        </div>
      </div>

    </div>


  )

}



export default Technologies