import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "../../assets/css/style.css";
import axios from "axios";
import "./Home.css";
import Banner from "../../components/banner/banner";
import Feature from "../../components/feature/feature";
import Testimonials from "../../components/testimonials/testimonials";
import Technologies from "../../components/technologies/technologies";
import Popup from "../../components/popup/popup";


const Home = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [message, setmessage] = useState("");

  const [scrollPosition, setScrollPosition] = useState(0);
  const [timedPopup, setTimedPopup] = useState(false)


  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimedPopup(true)
    }, 3000);
  }, [])


  // ServiceSlider
  var ServiceSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };


  const postData = async () => {
    const url = 'http://192.168.5.250:9009/api/lead/CreateLeadFromWbsite';

    const body = {
      name: name,
      email: email,
      phoneno: phoneno,
      message: message,
      service: "Bridge The Gap (BTG) Technologies | Creative Packages",
    };

    try {
      const response = await axios.post(url, body);
      console.log('Response:', response.data);
      // Handle the response data as needed
      setname("");
      setemail("");
      setphoneno("");
      setmessage("")
    } catch (error) {
      console.error('Error:', error);
      // Handle the error
    }
  };
  return (
    <div className="main">
  <Popup trigger={timedPopup} setTrigger={setTimedPopup} />
      <a onClick={() => setTimedPopup(true)} className={scrollPosition >= 500 ? 'side-btn active' : 'side-btn'}>Contact Us</a>
      <Banner
        heading={<h1><span>We Make</span> Good <span>Companies</span> Great</h1>}
        description={<p>Bridge The Gap (BTG) Technologies is the only name that guarantees the smoothest launch of your business!</p>}
        button={<a href="#services-sec" className="btn">Explore More</a>}
      />

      <div className="achievements">
        <div className="container">
          <Marquee gradient={false} width="100%" speed={50} direction="left">
            <img src={require('../../assets/images/home/badges/b1.png')} alt="" />
            <img src={require('../../assets/images/home/badges/b2.png')} alt="" />
            <img src={require('../../assets/images/home/badges/b3.png')} alt="" />
            <img src={require('../../assets/images/home/badges/b4.webp')} alt="" />
            <img src={require('../../assets/images/home/badges/b5.webp')} alt="" />
            <img src={require('../../assets/images/home/badges/b6.png')} alt="" />
            <img src={require('../../assets/images/home/badges/b7.png')} alt="" />
            <img src={require('../../assets/images/home/badges/b8.png')} alt="" />
          </Marquee>
        </div>
      </div>

      <div className="services-sec" id="services-sec">
        <div className="container">
          <div className="head-content">
            <div className="row">
              <div className="col-md-6">
                <span className="divider"></span>
                <p>Our tech-savvy enthusiasts, marketing gurus and seasoned designers leave no stone unturned when it comes to bringing result-driven solutions to your disposal. We pour our blood, sweat and tears to make your business the next big BRAND! Thinking How? We have a variety of solutions to sweeten your digital worth.</p>
                <a href="#contactUs" className="btn">Explore More</a>
              </div>
              <div className="col-md-5 offset-md-1">
                <h2><span>What Services</span> We Have In Our Arsenal?</h2>
              </div>
            </div>
          </div>
          <div className="slider">
            <Slider {...ServiceSlider}>
              <div>
                <div className="box">
                  <div className="text-area">
                    <img src={require('../../assets/images/home/services/s-img1.png')} alt="" />
                    <span className="number">01</span>
                    <h3>Logo Design</h3>
                    <p>Your logo is the beating heart of your brand, and Bridge The Gap (BTG) Technologies knows how to make it sing! From sleek and sophisticated to bold and dynamic, we'll capture your essence and turn it into a visual masterpiece.</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box">
                  <div className="text-area">
                    <img src={require('../../assets/images/home/services/s-img2.png')} alt="" />
                    <span className="number">02</span>
                    <h3>Website Design and Development</h3>
                    <p>Your website is your virtual storefront; we believe in making it a jaw-dropping experience. From seamless navigation to eye-catching visuals and compelling content, we'll create a digital masterpiece. </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box">
                  <div className="text-area">
                    <img src={require('../../assets/images/home/services/s-img3.png')} alt="" />
                    <span className="number">03</span>
                    <h3>Branding Design </h3>
                    <p>From logo and color palette selection to creating captivating brand guidelines, we'll ensure your brand has a distinct flavor that resonates with your target audience. </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box">
                  <div className="text-area">
                    <img src={require('../../assets/images/home/services/s-img4.png')} alt="" />
                    <span className="number">04</span>
                    <h3>Social Media Design</h3>
                    <p>From eye-catching graphics to thumb-stopping animations, we'll craft content that sparks conversations, drives engagement, and gets your brand noticed.</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box">
                  <div className="text-area">
                    <img src={require('../../assets/images/home/services/s-img5.png')} alt="" />
                    <span className="number">05</span>
                    <h3>Mobile Application</h3>
                    <p>Our expert developers will grant your wishes and bring your app dreams to life. From seamless user experiences to sleek designs and cutting-edge functionalities, we'll create an app that mesmerizes users.</p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <Feature />

      <div className="stats">
        <div className="row gx-0">
          {/* <div className="col-md-3">
            <div className="box">
              <h3>$750M+</h3>
              <p>Customer Revenue Growth</p>
            </div>
          </div> */}
          <div className="col-md-4">
            <div className="box">
              <h3>300+</h3>
              <p>Currennt Customer</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="box">
              <h3>900+</h3>
              <p>Our TALENTED TEAM</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="box">
              <h3>20K</h3>
              <p>Clients Happy</p>
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <h2>Our <span>Work</span> Process</h2>
              <h3>Sparking Ideas</h3>
              <p>We kick off our work process by brainstorming ideas. We mix and match concepts, shake things up like a cocktail, and let our creative juices flow like a wild river. From design inspirations to marketing strategies, we'll set your project ablaze with our fiery ideas.</p>
              <h3>Crafting Magic</h3>
              <p>Once we've captured the essence of your vision, we embark on a journey of craftsmanship. We meticulously sculpt designs, code websites with finesse, and create marketing campaigns that hit the bullseye.</p>
              <h3>Collaboration Extraordinaire</h3>
              <p>We foster a collaborative environment where your dreams take center stage. We listen to your desires, understand your needs, and twirl our expertise to deliver results. From start to finish, we will be your partners on this dance floor, creating a harmonious masterpiece together.</p>
              <h3>Sizzling Success</h3>
              <p>Our work process isn't complete until we've set the world ablaze with your project. We launch your designs, websites, and marketing campaigns like fireworks on a starry night. We aim to achieve sizzling success that leaves your competitors in the dust. So, let's set the world ablaze together!</p>
            </div>
            <div className="col-lg-7 offset-lg-1 col-md-7">
              <img className="side-img" src={require('../../assets/images/home/process/process-img.png')} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Technologies />

      <Testimonials />

    </div>


  )
}

export default Home