import React, { useState, useRef, useEffect } from "react";
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";


const PricePackage = [
  {
      id: 1,
      band_type: "logo_design",
      heading: "BASIC PACKAGE",
      sub_heading: ' BASIC PACKAGE',
      sf: "STARTING FROM",
      price: '$60.00',
      line1: "1 Logo Concepts",
      line2: "2 Revision Rounds",
      line3: "5 File Formats",
      line4: "24-48 turn around time",
      line5: "1 Dedicated Designers",
      line6: "24/7 Customer Support",
      line7: "100% Satisfaction Guaranteed",
      line8: "Dedicated Account Manager",
  },
  {
      id: 2,
      band_type: "logo_design",
      heading: "STANDARD PACKAGE",
      sub_heading: 'STANDARD PACKAGE',
      sf: "STARTING FROM",
      price: '$150.00',
      line1: "5 Logo Concepts",
      line2: "Unlimited Revision Rounds",
      line3: "5 File Formats Along with Source File",
      line4: "24-48 turn around time",
      line5: "2 Dedicated Designers",
      line6: "24/7 Customer Support",
      line7: "100% Satisfaction Guaranteed",
      line8: "Dedicated Account Manager",
      line9: "Money Back Guarantee",
  },
  {
      id: 3,
      band_type: "logo_design",
      heading: "PREMIUM PACKAGE",
      sub_heading: 'PREMIUM PACKAGE',
      sf: "STARTING FROM",
      price: '$300.00',
      line1: "8 Logo Concepts",
      line2: "Unlimited Revision Rounds",
      line3: "5 File Formats Along with Source File",
      line4: "24-48 turn around time",
      line5: "3 Dedicated Designers",
      line6: "Business Card Designs",
      line7: "Letterhead Designs",
      line8: "Envelop Designs",
      line9: "5 Mockups for apparels",
      line10: "24/7 Customer Support",
      line11: "100% Satisfaction Guaranteed",
      line12: "Dedicated Account Manager",
      line13: "Money Back Guarantee",
  },
  {
      id: 4,
      band_type: "website_design",
      heading: "Basic PACKAGE",
      sub_heading: ' BASIC PACKAGE',
      sf: "STARTING FROM",
      price: '$800/Year',
      line1: "One Page Website",
      line2: "Simple Logo",
      line3: "5 Image Slider",
      line4: "About Us Information",
      line5: "List of Services",
      line6: "Contact Details & Form",
      line7: "Google Map",
      line8: "10 Images Gallery",
      line9: "CMS for website update",
      line10: "5 Email Addresses Domain.co.us",
  },
  {
      id: 5,
      band_type: "website_design",
      heading: "STANDARD PACKAGE",
      sub_heading: 'STANDARD PACKAGE',
      sf: "STARTING FROM",
      price: '$1,300 / Year',
      line1: "5 Pages Website",
      line2: "Simple Logo",
      line3: "5 Image Slider",
      line4: "About Us",
      line5: "List Of Services",
      line6: "Contact Details & Form",
      line7: "Google Map",
      line8: "10 Images Gallery",
      line9: "CMS for website update",
      line10: "20 Email Addresses Domain.co.us",
      line11: "1 year hosting free (Linux)",
      line12: "Google index listing",
      line13: "SEO Optimization",
  },
  {
      id: 6,
      band_type: "website_design",
      heading: "PREMIUM PACKAGE",
      sub_heading: 'PREMIUM PACKAGE',
      sf: "STARTING FROM",
      price: '$1,800/Year',
      line1: "5 Pages Website",
      line2: "5 Image Slider",
      line3: "Logo with (2 Revisions)",
      line4: "About Us",
      line5: "List of Services",
      line6: "Contact Details & Form",
      line7: "Google Map",
      line8: "10 Images Gallery",
      line9: "CMS for website update",
      line10: "20 Email Addresses",
      line11: "Domain.co.us",
      line12: "Google Map",
      line13: "1 year hosting free (Linux)",
      line14: "Google index listing",
      line15: "SEO Optimization",
      line16: "Social Media Integration",
  },
//   {
//       id: 7,
//       band_type: "branding_design",
//       heading: "Bronze PACKAGE",
//       sub_heading: 'Perfect For Small Businesses',
//       price: '$1,950/month',
//       line1: "10  Keywords (SEO)",
//       line2: "Social Media Management (FB & Instagram)",
//       line3: "Google Ads Management (1 Campaigns)",
//       line4: "Online Content Creation",
//       line5: "Web Maintenance",
//       line6: "40 Approximate Backlinks",
//   },
//   {
//       id: 8,
//       band_type: "branding_design",
//       heading: "Silver PACKAGE",
//       sub_heading: 'Suitable For Mid-sized Brands',
//       price: '$3,000/month',
//       line1: "25  Keywords (SEO)",
//       line2: "Social Media Included (FB, Instagram, Twitter)",
//       line3: "Social Media Paid Marketing (FB & Instagram)",
//       line4: "Google Ads Management (2 Campaigns)",
//       line5: "Online Content Creation",
//       line6: "Web Maintenance",
//       line7: "90 Approximate Backlinks",
//   },
//   {
//       id: 9,
//       band_type: "branding_design",
//       heading: "Gold PACKAGE",
//       sub_heading: 'A Great Deal For Established Corporations',
//       price: '$5,000 /month',
//       line1: "50 Keywords (SEO)",
//       line2: "Marketing & Branding Services",
//       line3: "Social Media Management (Paid & Organic)",
//       line4: "Google Ads Management (3+ Campaigns)",
//       line5: "Online Content Creation",
//       line6: "Web Maintenance",
//       line7: "150 Approximate Backlinks",
//   },
  {
      id: 10,
      band_type: "social_media_design",
      heading: "BASIC PACKAGE",
      sub_heading: ' BASIC PACKAGE',
      sf: "STARTING FROM",
      price: '$1,500/month',
      line1: "10  Keywords (SEO)",
      line2: "Social Media Management (FB & Instagram)",
      line3: "Google Ads Management (1 Campaigns)",
      line4: "Online Content Creation",
      line5: "Web Maintenance",
      line6: "40 Approximate Backlinks",
  },
  {
      id: 11,
      band_type: "social_media_design",
      heading: "STANDARD PACKAGE",
      sub_heading: 'STANDARD PACKAGE',
      sf: "STARTING FROM",
      price: '$3,000/month',
      line1: "25  Keywords (SEO)",
      line2: "Social Media Included (FB, Instagram, Twitter)",
      line3: "Social Media Paid Marketing (FB & Instagram)",
      line4: "Google Ads Management (2 Campaigns)",
      line5: "Online Content Creation",
      line6: "Web Maintenance",
      line7: "90 Approximate Backlinks",
  },
  {
      id: 12,
      band_type: "social_media_design",
      heading: "PREMIUM PACKAGE",
      sub_heading: 'PREMIUM PACKAGE',
      sf: "STARTING FROM",
      price: '$5,000 /month',
      line1: "50 Keywords (SEO)",
      line2: "Marketing & Branding Services",
      line3: "Social Media Management (Paid & Organic)",
      line4: "Google Ads Management (3+ Campaigns)",
      line5: "Online Content Creation",
      line6: "Web Maintenance",
      line7: "150 Approximate Backlinks",
  },
  {
      id: 13,
      band_type: "mobile_application",
      heading: "BASIC PACKAGE",
      sub_heading: ' BASIC PACKAGE',
      sf: "STARTING FROM",
      price: "$3,500 USD",
      line1: "Logo Design (2 Variants)",
      line2: "Splash Screen Design (2 Variants)",
      line3: "App Developement (up to 15 Screens)",
      line4: "Play Store Optimization (One-Time)",
      line5: "Social Media Marketing (Facebook)",
  },
  {
      id: 14,
      band_type: "mobile_application",
      heading: "STANDARD PACKAGE",
      sub_heading: 'STANDARD PACKAGE',
      sf: "STARTING FROM",
      price: '$5,000 USD',
      line1: "Logo Design (2 Variants) (2 revisions)",
      line2: "Splash Screen Design (2 Variants) (2 revisions)",
      line3: "App Developement (up to 25 Screens)",
      line4: "Play Store Listing",
      line5: "App Store & Play Store Optimization (One-Time)",
      line6: "Social Media Marketing (Facebook, Instagram)",
  },
  {
      id: 15,
      band_type: "mobile_application",
      heading: "PREMIUM PACKAGE",
      sub_heading: 'PREMIUM PACKAGE',
      sf: "STARTING FROM",
      price: '$15,000 USD',
      line1: "Logo Design (3 Variants) (3 Revisions)",
      line2: "Splash Screen Design (3 Variants) (5 revisions)",
      line3: "App Developement  (up to 50 Screens)",
      line4: "App Store & Play Store Listing",
      line5: "App Store & Play Store Optimization",
      line6: "Social Media Marketing (Facebook,Instagram,Twitter)",
  },

]

const Pricings = () => {

  const [brandtype, setbrandtype] = useState('logo_design')

  return (
    <div className="">
    
    <div className="pricing" id="price">
      <div className="container">
          <div className="row align-items-center">
              <div className="col-md-10 offset-md-1">
                  <div className="top-content">
                      <h2>Choose Your <span>Pricing Options</span> for Our Services</h2>
                      <p>Our pricing plans are appropriate for businesses of all sizes, with customizable options to suit your requirements and budget. Discover the ideal strategy for achieving your objectives.</p>
                      <ul>
                          <li className={brandtype === "logo_design" ? 'active' : ''} ><button onClick={() => setbrandtype("logo_design")}>Logo Design</button></li>
                          <li className={brandtype === "website_design" ? 'active' : ''} ><button onClick={() => setbrandtype("website_design")}>Website Design</button></li>
                          {/* <li className={brandtype === "branding_design" ? 'active' : ''} ><button onClick={() => setbrandtype("branding_design")}>Branding Design</button></li> */}
                          <li className={brandtype === "social_media_design" ? 'active' : ''} ><button onClick={() => setbrandtype("social_media_design")}>Social Media Marketing</button></li>
                          <li className={brandtype === "mobile_application" ? 'active' : ''} ><button onClick={() => setbrandtype("mobile_application")}>Mobile Application</button></li>
                      </ul>
                  </div>
              </div>
          </div>
          <div className="row">
              {PricePackage.filter((item) => item.band_type === brandtype).map((item) => (
                  <div className="col-md-6 col-lg-4">
                      <div className="box">
                          <span className="side-title">{item.heading}</span>
                          <div className="b-head">
                              <h3>{item.sub_heading}</h3>
                              {/* <span className="price" style={{fontSize: '1rem'}}></span> */}
                              <h3>{item.sf}</h3>
                              <h3>{item.price}</h3>
                          </div>
                          <div className="b-body">
                              <ul>
                                  <li>{item.line1}</li>
                                  <li>{item.line2}</li>
                                  <li>{item.line3}</li>
                                  <li>{item.line4}</li>
                                  <li>{item.line5}</li>
                                  <li>{item.line6}</li>
                                  <li>{item.line7}</li>
                                  <li>{item.line8}</li>
                                  <li>{item.line9}</li>
                                  <li>{item.line10}</li>
                                  <li>{item.line11}</li>
                                  <li>{item.line12}</li>
                                  <li>{item.line13}</li>
                                  <li>{item.line14}</li>
                                  <li>{item.line15}</li>
                                  <li>{item.line16}</li>

                              </ul>
                          </div>
                          <div className="b-foot">
                              <a href="#contactUs" className="action-btn">
                                  <img src={require('../../assets/images/services/b-icon.png')} alt="" />
                                  <div>
                                      <span className="txt1">ORDER NOW</span>
                                      <span className="txt2">FEEL FREE TO CHAT</span>
                                  </div>
                              </a>
                              <a href="tel:+17273080419" className="action-btn">
                                  <img src={require('../../assets/images/services/phone-icon.png')} alt="" />
                                  <div>
                                      <span className="txt1">CALL US NOW</span>
                                      <span className="txt2">+1 727 308 0419</span>
                                  </div>
                              </a>
                          </div>
                      </div>
                  </div>
              ))}

          </div>
      </div>
  </div>

    </div>


  )

}



export default Pricings