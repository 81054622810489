import React, { useState, useRef, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";

const FAQ = () => {

  const [show, setShow] = React.useState();

  return (
    <div className="">
      <div className="faq-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h3>Frequently <span>Asked Question</span></h3>
              <p>Here you will find quick and easy answers to your questions by saving time and effort. So, dive in and get the solutions you've been seeking for.</p>
              <div className="faq-list">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Why should you prefer Bridge The Gap (BTG) Technologies over other IT Solution companies?</Accordion.Header>
                  <Accordion.Body>
                  We have rich experience in solving all kinds of designing and development services, such as working alongside existing development teams, working with legacy systems to support new app and web designing and developments, building technically complex features, and bringing together systems with seamless integration.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>What Services do you provide?</Accordion.Header>
                  <Accordion.Body>
                  We create modern and unique designing and development services, including logo designing, branding, social media, mob app design, web designing, and development. Our services help customers all around the globe by elevating businesses through tested processes.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>What does it cost to hire you?</Accordion.Header>
                  <Accordion.Body>
                  You can get accurate pricing information on our website's pricing page. We also prioritize our customers' demands and develop customized proposals for them.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>What is app UI design?</Accordion.Header>
                  <Accordion.Body>
                  UI(User Interface) is the point of contact between computers and humans. So, any tech you interact with as a user is part of the UI. It includes screens, overall style, sounds, and responsiveness. 
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>What is the difference between static and dynamic design?</Accordion.Header>
                  <Accordion.Body>
                  Dynamic design changes quite often, while static designs maintain the same. One other point of distinction is development, as static designs are smoother than dynamic ones.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Do you offer a custom logo design service?</Accordion.Header>
                  <Accordion.Body>
                  We provide professionally designed logos that will bring confidence to you. We have faith that it will give you insight into the workings and benefits of your business.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>How do you provide branding services?</Accordion.Header>
                  <Accordion.Body>
                  We provide our branding service through a few distinct steps:
                  <ul>
                    <li>Find target audience</li>
                    <li>Develop a value proposition</li>
                    <li>Determine mission</li>
                    <li>Define Brand Personality</li>
                    <li>Create assets</li>
                    <li>Integration across channels</li>
                    <li>Maintain consistency</li>
                  </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Will I own the source code?</Accordion.Header>
                  <Accordion.Body>
                  Yes, we will give you the proper copyright or license rights for the custom code we create for your project. We also offer transparency regarding intellectual property rights.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>How do you keep my app and data secure?</Accordion.Header>
                  <Accordion.Body>
                  We provide protection and susceptibility audits as part of project deployment using industry-recognized penetration testing technologies to ensure the security of your app and data.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>How do you develop a social media strategy?</Accordion.Header>
                  <Accordion.Body>
                  To create your social media plan, we must first determine your social media goals. We can utilize this information to personalize it around the content, whether it's an increase in sales, inquiries, or website traffic.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>
            </div>
            <div className="col-md-4">
            <img src={require('../../assets/images/testimonials/faq-img.png')} alt="" />
            </div>
          </div>
        </div>
      </div>

    </div>


  )

}



export default FAQ